<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="searchParams.dynastyName" size='small' style="width:150px;margin:5px;" placeholder='朝代'></a-input>
        <a-input v-model="searchParams.rulerName" size='small' style="width:150px;margin:5px;" placeholder='皇帝'></a-input>
        <a-input v-model="searchParams.rulerDynastyName" size='small' style="width:150px;margin:5px;" placeholder='年号'></a-input>
        <a-button  @click="search" size='small' style="margin-left:10px;">搜索</a-button>
      </div>
      <div>
        <a-button @click="openModal({})" size='small'>新增皇帝</a-button>
      </div>
    </div>
    <div class="table-content" style="padding-top:20px;">
      <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>record.id||n">
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style='margin-right:5px'>编辑</a>
          <a-popconfirm
              title="Are you sure delete this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="remove(row.id)"
          >
            <a style='margin-left:5px'>删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal v-model="visible" :title="innerTitle" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
      <a-form-model
          ref="ruleForm"
          :model="forms"
          :rules="rules"
          v-bind="layout">
        <a-form-model-item label="朝代" prop="dynastyId" >
          <a-select
              :filter-option="untils.filterOption"
              showSearch
              v-model="forms.dynastyId"
              placeholder='请选择朝代'
              @search="getDynastyList"
          >
            <a-select-option  v-for="item of dynasty_list" :key="item.id" :value='item.id'>{{item.dynastyName}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="皇帝" prop="rulerName" >
          <a-input placeholder='请输入皇帝' v-model='forms.rulerName'></a-input>
        </a-form-model-item>
        <a-form-model-item label="年号" prop="rulerDynastyName" >
          <a-input placeholder='请输入年号' v-model='forms.rulerDynastyName'></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import untils from "@/untils"

export default {
  data(){
    return {
      untils,
      columns:[
        {title:'ID',dataIndex:'id'},
        {title:'朝代',dataIndex:'dynastyName'},
        {title:'皇帝',dataIndex:'rulerName'},
        {title:'年号',dataIndex:'rulerDynastyName'},
        {title:'操作',align:'center',scopedSlots: { customRender: 'setup' }},
      ],
      data:[],
      searchParams:{
        limit:10,
        page:1,
        dynastyName:'',
        rulerName:'',
        rulerDynastyName:'',
      },
      rules:{
        dynastyId:[{ required: true, message: 'please select', trigger: 'blur' }],
        rulerName:[{ required: true, message: 'please enter', trigger: 'blur' }],
        rulerDynastyName:[{ required: true, message: 'please enter', trigger: 'blur' }],
      },
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      forms:{},
      visible:false,
      loading:false,
      innerTitle:'',
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      dynasty_list:[],
      searchDynastyParams: {
        pageNum: 1,
        pageSize: 10,
        type: undefined,
        dynastyName: undefined
      },
    }
  },
  computed:{
  },
  watch:{
  },
  async created(){
    await this.getDynastyList('')
    await this.getData(this.searchParams)
  },
  methods:{
    /** 获取朝代--朝代列表 */
    async getDynastyList(searchStr) {
      this.searchDynastyParams.dynastyName = searchStr
      this.searchDynastyParams.type = 0
      await this.axios("/dq_admin/dynasty/newlist", {
        params: {
          ...this.searchDynastyParams,
        }
      }).then((res) => {
        const { records } = res.data;
        this.dynasty_list = records;
      });
    },
    openModal(row){
      const {id} = row
      this.innerTitle = id ? '编辑皇帝' : '新增皇帝'
      this.forms = JSON.parse(JSON.stringify(row))
      this.visible = true
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/ruler/list',{params:params})
      this.loading = false
      const {records,total} = res.data
      this.data = records
      this.pagination.total = total
    },
    search(){
      this.searchParams.page = 1
      this.pagination.current = 1
      this.getData(this.searchParams)
    },
    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.searchParams.page = current
      this.searchParams.limit = pageSize
      this.getData(this.searchParams)
    },
    resetForms(){
      this.forms = {}
      this.$refs.ruleForm.resetFields();
    },
    hideModal(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false;
        }
      });
    },
    submit(forms){
      const {id} = forms
      const url = id ? '/dq_admin/ruler/edit' : '/dq_admin/ruler/add'
      this.axios.post(url,forms).then(res=>{
        if(res.status == 'SUCCESS'){
          this.$message.success(res.message)
          this.visible = false
          this.getData(this.searchParams)
        }
      })
    },
    async remove(params){
      const res = await this.axios('/dq_admin/ruler/delRulerById',{params:{id:params}})
      if(res.status == 'SUCCESS'){
        this.$message.success(res.message)
        this.getData(this.searchParams)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>